/* Change this file to get your personal Porfolio */

// Website related settings
const settings = {
  isSplash: false, // Change this to false if you don't want Splash screen.
};

//SEO Related settings
const seo = {
  title: "Wali Khawaja Portfolio",
  description:
    "Hey, i'm a Computer Science student looking for oppertunities in Android Development to create great products. I'm passionate about my work and look forward to make changes to improve value to customers as well as deliver great products to production.  ",
  og: {
    title: "Wali Khawaja Portfolio",
    type: "website",
    url: "http://walikhawaja.com/",
  },
};

//Home Page
const greeting = {
  title: "Wali Khawaja",
  logo_name: "Wali Khawaja",
  nickname: "Android Developer",
  subTitle:
    "Hey, i'm a Computer Science student looking for opportunities in Android Development to create great products. I'm passionate about my work and look forward to make changes to improve value to customers as well as deliver great products to production.",
  resumeLink:
    "https://www.dropbox.com/s/06d42px40donjzz/Wali%20Khawaja%20Resume%2001.pdf?dl=0",
  portfolio_repository: "https://github.com/walikhawaja01",
};

const socialMediaLinks = [
 

  {
    name: "Github",
    link: "https://github.com/walikhawaja01",
    fontAwesomeIcon: "fa-github", // Reference https://fontawesome.com/icons/github?style=brands
    backgroundColor: "#181717", // Reference https://simpleicons.org/?q=github
  },
  {
    name: "LinkedIn",
    link: "https://www.linkedin.com/in/wali-khawaja-33b62419a/",
    fontAwesomeIcon: "fa-linkedin-in", // Reference https://fontawesome.com/icons/linkedin-in?style=brands
    backgroundColor: "#0077B5", // Reference https://simpleicons.org/?q=linkedin
  },
  {
    name: "YouTube",
    link: "https://www.youtube.com",
    fontAwesomeIcon: "fa-youtube", // Reference https://fontawesome.com/icons/youtube?style=brands
    backgroundColor: "#FF0000", // Reference https://simpleicons.org/?q=youtube
  },
  {
    name: "Gmail",
    link: "mailto:walikhawaja01@gmail.com",
    fontAwesomeIcon: "fa-google", // Reference https://fontawesome.com/icons/google?style=brands
    backgroundColor: "#D14836", // Reference https://simpleicons.org/?q=gmail
  },
  
  {
    name: "Facebook",
    link: "https://www.facebook.com/wali.khawaja.3",
    fontAwesomeIcon: "fa-facebook-f", // Reference https://fontawesome.com/icons/facebook-f?style=brands
    backgroundColor: "#1877F2", // Reference https://simpleicons.org/?q=facebook
  },
  {
    name: "Instagram",
    link: "https://www.instagram.com/wali.khawaja/",
    fontAwesomeIcon: "fa-instagram", // Reference https://fontawesome.com/icons/instagram?style=brands
    backgroundColor: "#E4405F", // Reference https://simpleicons.org/?q=instagram
  },
];

const skills = {
  data: [
    {
      title: "Android Development",
      fileName: "DataScienceImg",
      skills: [
        "⚡ Developing Android Applications on Android SDK",
        "⚡ Experience working with projects such as QR Code Scanner/reader, Firebase projects requiring voluntary user data input and calculations ",
        "⚡ Focus on dynamic UI design for applications to increase positive user response",
      ],
      softwareSkills: [
        {
          skillName: "",
          fontAwesomeClassname: "logos-android",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "Kotlin",
          fontAwesomeClassname: "logos-kotlin",
          style: {
            backgroundColor: "transparent",
            //color: "#D00000",
          },
        },
        {
          skillName: "Java",
          fontAwesomeClassname: "logos-java",
          style: {
            backgroundColor: "transparent",
          },
        },
        
      ],
    },
    {
      title: "Full Stack Development",
      fileName: "FullStackImg",
      skills: [
        "⚡ Building resposive website's front end using React-Redux",
        "⚡ Developing mobile applications on android using Kotlin",
        "⚡ Exprience developing UI with Java and exprience in data structures and algorithms",
      ],
      softwareSkills: [
        {
          skillName: "HTML5",
          fontAwesomeClassname: "simple-icons:html5",
          style: {
            color: "#E34F26",
          },
        },
        {
          skillName: "CSS3",
          fontAwesomeClassname: "fa-css3",
          style: {
            color: "#1572B6",
          },
        },
        {
          skillName: "Kotlin",
          fontAwesomeClassname: "logos-kotlin",
          style: {
            color: "#CC6699",
          },
        },
        {
          skillName: "JavaScript",
          fontAwesomeClassname: "simple-icons:javascript",
          style: {
            backgroundColor: "#000000",
            color: "#F7DF1E",
          },
        },
        {
          skillName: "ReactJS",
          fontAwesomeClassname: "simple-icons:react",
          style: {
            color: "#61DAFB",
          },
        },
        {
          skillName: "NodeJS",
          fontAwesomeClassname: "simple-icons:node-dot-js",
          style: {
            color: "#339933",
          },
        },
        {
          skillName: "NPM",
          fontAwesomeClassname: "simple-icons:npm",
          style: {
            color: "#CB3837",
          },
        },
      ],
    },
    {
      title: "Database ",
      fileName: "CloudInfraImg",
      skills: [
        "⚡ Experience working on multiple database platforms such as Firebase, SQL server, and SQL lite with android SDK",
        "⚡  Senior Project will focus on hosting and maintaining an Android application with the integration of databases",
        "⚡  Experience hosting Discord bot on AWS server ",
      
      ],
      softwareSkills: [
        
        {
          skillName: "AWS",
          fontAwesomeClassname: "simple-icons:amazonaws",
          style: {
            color: "#FF9900",
          },
        },
        
        {
          skillName: "Firebase",
          fontAwesomeClassname: "simple-icons:firebase",
          style: {
            color: "#FFCA28",
          },
        },
        {
          skillName: "SQL",
          fontAwesomeClassname: "logos-mysql",
          style: {
            color: "#336791",
          },
        },
        
        {
          skillName: "SQLite",
          fontAwesomeClassname: "simple-icons:sqlite",
          style: {
            color: "#326CE5",
          },
        },
      ],
    },
    
  ],
};

// Education Page
const competitiveSites = {
  competitiveSites: [
    
    {
      siteName: "Android Development Course",
      iconifyClassname: "simple-icons:android",
      style: {
        color: "#1F8ACB",
      },
      profileLink: "https://developers.google.com/profile/u/107394062191244457518?utm_source=developers.google.com",
    },
    {
      siteName: "LinkedIn",
      iconifyClassname: "simple-icons:linkedin",
      style: {
        color: "#323754",
      },
      profileLink: "https://www.linkedin.com/in/wali-khawaja-33b62419a/",
    },
    {
      siteName: "GitHub",
      iconifyClassname: "logos-github",
      style: {
        color: "#20BEFF",
      },
      profileLink: "https://github.com/walikhawaja01",
    },
  ],
};

const degrees = {
  degrees: [
    {
      title: "University of Houston Downtown College of Technology ",
      subtitle: "B.S in Computer Science  ",
      logo_path: "uhd.png",
      alt_name: "University of Houston Downtown",
      duration: "2019 - Fall 2021",
      descriptions: [
        "Graduating GPA: 3.5",
        "I have studied software engineering subjects at university such as Data Structures & Algorithms, Digital logic, Software Engineering and various more.",
        "Apart from this, I have done courses on my own time such as Kotlin development with Google using Android SDK, and various react website tutorials.",
        "During my time at University I was given the opportunity to teach Data Structures and Algorithms through the SI program.",
      ],
      website_link: "https://www.uhd.edu/academics/university-college/centers-offices/supplemental-instruction/Pages/default.aspx",
    },
  ],
};

const certifications = {
  certifications: [
    {
      title: "Microsoft Office Suite",
      subtitle: "",
      logo_path: "office.png",
      certificate_link:
        "https://www.microsoft.com/en-us/microsoft-365/products-apps-services",
      alt_name: "MicrosoftOfficeSuite",
      color_code: "#8C151599",
    },
    {
      title: "C++",
      subtitle: "",
      logo_path: "c-.png",
      certificate_link:
        "https://code.visualstudio.com/docs/languages/cpp",
      alt_name: "cpluspluslang",
      color_code: "#00000099",
    },
    {
      title: "Kotlin",
      subtitle: "",
      logo_path: "kotlin-icon.png",
      certificate_link:
        "https://developer.android.com/courses",
      alt_name: "kotlin-skill",
      color_code: "#0C9D5899",
    },
    {
      title: "Communication",
      subtitle: "",
      logo_path: "chat.png",
      certificate_link:
        "",
      alt_name: "commSkill",
      color_code: "#1F70C199",
    },
    {
      title: "Python",
      subtitle: "",
      logo_path: "python.png",
      certificate_link:
        "https://www.jetbrains.com/pycharm/",
      alt_name: "Pythonskill",
      color_code: "#D83B0199",
    },
    {
      title: "SQL",
      subtitle: "",
      logo_path: "sql.png",
      certificate_link:
        "https://dev.mysql.com/downloads/mysql/",
      alt_name: "SQLSkill",
      color_code: "#1F70C199",
    },
    {
      title: "Data Structures & Algorithms",
      subtitle: "",
      logo_path: "structured-data.png",
      certificate_link:
        "https://www.crackingthecodinginterview.com/contents.html",
      alt_name: "datasanda",
      color_code: "#0C9D5899",
    },
    {
      title: "Unit Testing",
      subtitle: "Android SDK",
      logo_path: "test.png",
      certificate_link:
        "https://developer.android.com/training/testing/unit-testing",
      alt_name: "unittesting",
      color_code: "#00000099",
    },
    {
      title: "Time Managment",
      subtitle: "",
      logo_path: "time-management.png",
      certificate_link:
        "https://www.forbes.com/sites/johnrampton/2018/05/01/manipulate-time-with-these-powerful-20-time-management-tips/?sh=5cba89b457ab",
      alt_name: "time-manag",
      color_code: "#2A73CC",
    },
    {
      title: "Leadership",
      subtitle: "",
      logo_path: "leadership.png",
      certificate_link:
        "https://www.uhd.edu/academics/university-college/centers-offices/supplemental-instruction/Pages/default.aspx",
      alt_name: "leadershipskill",
      color_code: "#4285F499",
    },
    {
      title: "Java",
      subtitle: "",
      logo_path: "java.png",
      certificate_link:
        "https://www.oracle.com/java/technologies/downloads/",
      alt_name: "javaskill",
      color_code: "#FFBB0099",
    },
    {
      title: "React",
      subtitle: "",
      logo_path: "react.png",
      certificate_link:
        "https://reactjs.org/docs/getting-started.html",
      alt_name: "reactskill",
      color_code: "#4285F499",
    },
  ],
};

// Experience Page
const experience = {
  title: "Experience",
  subtitle: "Work and Volunteering",
  description:
    "I've had the pleasure of working with different companies during my time at university both professionally and time spent volunteering. Thanks to these companies I was able to showcase my talents and polish my skills in communication and team building. Throughout my career, I've managed to work myself up into leadership roles in which I've been able to learn how to manage, work with and adapt to different characters, cultures and values.",
  header_image_path: "experience.svg",
  sections: [
    {
      title: "Work",
      experiences: [
        {
          title: "Supplemental Instruction",
          company: "University of Houston",
          company_url: "https://www.uh.edu/human-resources/",
          logo_path: "uhsystem.png",
          duration: "2021 - PRESENT",
          location: "Houston, Texas",
          description:
            "At University of Houston, I administered regular supplemental sessions to a class of approximately 25 students on a weekly basis. In addition to this, I created weekly lesson plans on static and dynamic data structures, provided informative presentations to students on implementation of Arrays, 2D-Vectors, Stack, Recursion and Binary Search Tree. As a Mentor, I delegated tasks to SI leaders and oversaw operations in COMPSCI department. ",
          color: "#33FF9E",
        },
        {
          title: "Team Lead",
          company: "Houston Sugar land Methodist",
          company_url: "https://www.houstonmethodist.org/locations/sugar-land/",
          logo_path: "houstonmethodist.png",
          duration: "2017 - 2021",
          location: "Sugar Land, Texas",
          description:
            "As team lead, I supervised a staff of 12 employees to complete daily operations for approximately 280 inpatients weekly. Responsibilities consisted of maintaining weekly inventory balance, safety and sanitation inspections meeting federal standards, and schedule management. Furthermore, I worked with a team to create improvements to patient satisfaction and care through retrospective meetings with patients and patient care staff.  ",
          color: "#0AB664",
        },
       
      ],
    },
    {
      title: "Volunteering",
      experiences: [
        {
          title: "Radiology / Front Desk / General volunteer",
          company: "Texas Children's Hospital",
          company_url: "https://www.texaschildrens.org/departments/texas-childrens-hospital-west-campus",
          logo_path: "texasC.png",
          duration: "March 2018",
          location: "Houston, Texas",
          description:
            "Assisted Hospital staff with daily tasks such as transportation of files, patients, and various other items to increase quality and efficiency of the experience. I also had the pleasure of working with great individuals who volunteered their free time to help take care of and cheer up children during a difficult time. Volunteering at Texas Children allowed me to communicate with a variety of different people as well as explore different career paths available.",
          color: "#0D5734",
        },
       
      ],
    },
  ],
};

// Projects Page
const projectsHeader = {
  title: "Projects",
  description:
    "My projects makes use of vast variety of latest technology tools. I wanted to develop projects which would not only produce positive user results, but also help increase my skills and capacity along the way. Throughout my time at University, I’ve had the pleasure of working on various projects spanning across different fields in computer science. In addition to this, I took on the challenge of creating many personal projects to showcase my ideas and increase my potential.",
  avatar_image_path: "projects_image.svg",
};

const publicationsHeader = {
  title: "University Projects",
  description:
    "Here are some of the projects i've worked on both individually and in development teams.",
  avatar_image_path: "projects_image.svg",
};

// Contact Page
const contactPageData = {
  contactSection: {
    title: "Contact Me",
    profile_image_path: "man.png",
    description:
      "Hey, lets get in touch! You can contact me on any social media platform, or email for any opportunities .",
  },
  blogSection: {
    title: "Discord",
    subtitle:
      "For most nights when i'm not working on projects, you can find me hanging out on Discord. Come connect with me. ",
    link: "https://discordapp.com/users/Wali#5976/",
    avatar_image_path: "blogs_image.svg",
  },
  addressSection: {
    title: "Location",
    subtitle:
      "Houston, Texas",
    avatar_image_path: "address_image.svg",
    location_map_link: "https://www.google.com/maps/place/Houston,+TX/@29.7604267,-95.3698028,11z/data=!4m13!1m7!3m6!1s0x8640b8b4488d8501:0xca0d02def365053b!2sHouston,+TX!3b1!8m2!3d29.7604267!4d-95.3698028!3m4!1s0x8640b8b4488d8501:0xca0d02def365053b!8m2!3d29.7604267!4d-95.3698028",
  },
  phoneSection: {
    title: "Phone Number",
    subtitle: "(832)-768-8539",
  },
};

export {
  settings,
  seo,
  greeting,
  socialMediaLinks,
  skills,
  competitiveSites,
  degrees,
  certifications,
  experience,
  projectsHeader,
  publicationsHeader,
  contactPageData,
};



/*
{
      title: "UI/UX Design",
      fileName: "DesignImg",
      skills: [
        "⚡ Designing highly attractive user interface for mobile and web applications",
        "⚡ Customizing logo designs and building logos from scratch",
        "⚡ Creating the flow of application functionalities to optimize user experience",
      ],
      softwareSkills: [
        {
          skillName: "Adobe XD",
          fontAwesomeClassname: "simple-icons:adobexd",
          style: {
            color: "#FF2BC2",
          },
        },
        {
          skillName: "Figma",
          fontAwesomeClassname: "simple-icons:figma",
          style: {
            color: "#F24E1E",
          },
        },
        {
          skillName: "Adobe Illustrator",
          fontAwesomeClassname: "simple-icons:adobeillustrator",
          style: {
            color: "#FF7C00",
          },
        },
        {
          skillName: "Inkscape",
          fontAwesomeClassname: "simple-icons:inkscape",
          style: {
            color: "#000000",
          },
        },
      ],
    },





*/